import React from 'react';
import { Container } from '../styles/center';

const Store = () => {
  return (
    <Container>
      <h1>Store</h1>
    </Container>
  );
};

export default Store;
