import React from 'react';
import { Container } from '../styles/center';

const Gmail = () => {
  return (
    <Container>
      <h1>Gmail</h1>
    </Container>
  );
};

export default Gmail;
